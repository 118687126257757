export default [
    {
        value: 'no',
        label : "Non, je ne l'ai pas testée",
        isSelected : false,
    },
    {
        value: 'yesNeverTryAgain',
        label : "Oui je l'ai testée mais je n'ai pas recommencé",
        isSelected : false,
    },
    {
        value: 'yesTrySometimes',
        label : "Oui je l'ai testée et je recommence de temps en temps",
        isSelected : false,
    },
    {
        value: 'yes',
        label : "Oui je l'ai testée et ce nouveau mode de déplacement est devenu une vraie habitude",
        isSelected : false,
    }
]