<template>
    <!-- <i class="icon-iconmobilite_autre"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span></i> -->
    <img src="../../assets/icons/other.png" alt="icon-other" />
</template>

<script>
    export default {
        name: 'IconOther',
        props: [],
        data(){
            return {
            }
        },
    }
</script>