import axios from 'axios'

axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

export default {
    async getOnePlan(planId) {
        return await axios.get(`${process.env.VUE_APP_API_BASE_URL}/plans/${planId}`, {})
    },
    async getTripsForPlan(planId) {
        return await axios.get(`${process.env.VUE_APP_API_BASE_URL}/trips/plan/${planId}`, {})
    },
    async recordSurvey(planId, data) {
        return await axios.put(`${process.env.VUE_APP_API_BASE_URL}/plans/survey/${planId}`, data)
    },
}