import Vue from 'vue'
import VueRouter from 'vue-router'
import Survey from '@/views/Survey.vue'
import Error from '@/views/Error.vue'
import Success from '@/views/Success.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/survey/:planId',
    name: 'Survey',
    component: Survey
  },
  {
    path: '/success',
    name: 'Success',
    component: Success
  },
  {
    path: '/error',
    name: 'Error',
    component: Error
  },
  // catch all redirect
  { path: '*', redirect: {name: 'Error'}}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
