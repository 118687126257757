<template>
    <div class="survey-container">
        <div>
            <h1 class="text-blue text-2xl font-bold pb-10">Il y a quelque temps, je m'étais engagé.e à :</h1>
            <div class="flex flex-col md:flex-row">
                <trip-changes 
                    v-for="(tripChange, index) in formattedTrips" 
                    :key="index"
                    :trip="tripChange"
                    class="mb-10 md:mb-0"/>
            </div>
            
        </div>
        <el-collapse v-model="activeTab" accordion class="mt-10 mx-10 md:mx-0">
            <el-collapse-item title="Question 1" name="question">
                <div class="flex-row text-left">
                    <p class="text-lg pb-4 text-center">As-tu testé cette nouvelle façon de te déplacer ?</p>
                    <el-radio-group v-model="newWay">
                        <el-radio 
                            v-for="(newWay, index) in newWaysToMove"
                            :key="index" 
                            :label="newWay.value"
                            class="block py-1"
                            @change="updateNewWayToMove">{{newWay.label}}</el-radio>
                    </el-radio-group>
                </div>
            </el-collapse-item>
            <el-collapse-item v-if="choicesByWay && choicesByWay.key != 'yes'" title="Question 2" name="subQuestion">
                <div v-if="subComponent">
                    <p class="text-lg pb-4 text-center">{{choicesByWay.label}}</p>
                    <choices 
                        :choices="choicesByWay.options" 
                        v-model="whyNewWay"
                        @update="updateSurvey" />
                </div>
            </el-collapse-item>
        </el-collapse>
        <div v-show="isRessourcesDisplay" class="mt-10 mx-10 md:mx-0">
            <div v-if="choicesByWay">
                <div class="pb-8">
                    <p class="text-left">Avez-vous des commentaires ou précisions à apporter ?</p>
                    <el-input
                        type="textarea"
                        :rows="2"
                        placeholder="Ecrivez votre commentaire..."
                        v-model="commentSurvey">
                    </el-input>
                </div>
                <p v-html="choicesByWay.ressourcesLabel"></p>
            </div>
            <div class="p-4 mt-6 bg-blue-100 rounded-lg">
                <p>J’ai envie d’être informé.e sur les prochains événements relatifs à la mobilité durable portés par l’Agglomération de La Rochelle et le collectif Agremob.</p>
                <el-radio-group v-model="moreInformations" class="mt-6">
                    <el-radio :label="true">OUI</el-radio>
                    <el-radio :label="false">NON</el-radio>
                </el-radio-group>
            </div>
        </div>
        <cta-footer
            text="VALIDER"
            @send="recordSurvey" />
    </div>
</template>

<script>
    import ChoicesTemplate from "@/components/Choices.vue"

    import CtaFooter from '@/components/CtaFooter.vue'

    import Choices from '@/config/choices'

    import SurveyApi from '@/api/Survey'
    import TripChanges from '@/components/TripChanges.vue'

    import TRIPS from '@/config/trips'
    import TRANSPORT from '@/config/transports'
    import NEWWAYS from '@/config/newWays'

    import { Message } from 'element-ui';

    export default {
        name: "Survey",
        data(){
            return {
                activeTab: 'question',
                titleSubQuestion: '',
                newWaysToMove: NEWWAYS,
                newWay : false,
                whyNewWay: [],
                moreInformations : null,
                choices : Choices,
                plan : {},
                planTrips: [],
                trips: TRIPS,
                transports: TRANSPORT,
                commentSurvey: ''
            }
        },
        async created(){
            if(this.$route.params.planId){
                let plan        = await SurveyApi.getOnePlan(this.$route.params.planId)
                let trips       = await SurveyApi.getTripsForPlan(this.$route.params.planId)
                this.plan       = plan.data;
                this.planTrips  = trips.data
            }
        },
        methods: {
            updateNewWayToMove(){
                this.whyNewWay = []
                this.activeTab = "subQuestion"
            },
            updateSurvey(data){
                this.whyNewWay = data
            },
            recordSurvey(){
                if(this.moreInformations !== null){
                    let response = SurveyApi.recordSurvey(this.$route.params.planId, this.preFormatData)
                    this.$router.push({name: 'Success'})
                } else{
                    Message({
                        type: 'error',
                        message: 'Merci de renseigner une réponse à la question dans l\'encadré bleu à la fin du questionnaire.'
                    });
                }
                
            },
        },
        computed: {
            newWaySelected(){
                let item = this.newWaysToMove.find(newWayToMove => newWayToMove.isSelected)
                return item ? item : false
            },
            choicesByWay(){
                if(this.newWay){
                    return this.choices[this.newWay]
                }
                return false
            },
            subComponent(){
                if(this.newWay){
                    return true
                }
                return false
            },
            formattedTrips(){
                let planTripsFormatted = []
                this.planTrips.forEach(planTrip => {
                    if (planTrip.tripTypeId != null && planTrip.transportTypeId != null) {
                        let tripConfig = this.trips.find(trip => trip.id === planTrip.tripTypeId)
                        let transportConfig = this.transports.find(transport => transport.value === planTrip.transportTypeId)

                        let dataToMerge = {
                            tripLabel: tripConfig ? tripConfig.label : '',
                            tripIcon: tripConfig ? tripConfig.icon : '',
                            transportLabel: transportConfig ? transportConfig.label : '',
                            frequenceLabel: (planTrip.frequence != null ? planTrip.frequence : 0) + ` fois par ${planTrip.tripTypeId == 'holiday' ? 'an' : 'mois'}`,
                        }
                        if (planTrip.hasOccasionalTrip) {
                            let occTransportConfig = this.transports.find(transport => transport.value === planTrip.occTransportTypeId)
                            dataToMerge = {
                                ...dataToMerge, ...{
                                    occTransportLabel: occTransportConfig ? occTransportConfig.label : '',
                                    occFrequenceLabel: (planTrip.occFrequence != null ? planTrip.occFrequence : 0) + ` fois par ${planTrip.tripTypeId == 'holiday' ? 'an' : 'mois'}`,
                                }
                            }
                        }

                        if (planTrip.newTransportTypeId != null || planTrip.newFrequence != null){
                            let newTransportConfig = this.transports.find(transport => transport.value === planTrip.newTransportTypeId)
                            dataToMerge = {
                                ...dataToMerge, ...{
                                    newTransportLabel: newTransportConfig ? newTransportConfig.label : dataToMerge.transportLabel,
                                    newFrequenceLabel: planTrip.newFrequence != null ? (planTrip.newFrequence != null ? planTrip.newFrequence : 0) + ` fois par ${planTrip.tripTypeId == 'holiday' ? 'an' : 'mois'}` : dataToMerge.frequenceLabel,
                                }
                            }
                        }
                        if (planTrip.newOccTransportTypeId != null || planTrip.newOccFrequence != null){
                            let newOccTransportConfig = this.transports.find(transport => transport.value === planTrip.newOccTransportTypeId)
                            dataToMerge = {
                                ...dataToMerge, ...{
                                    newOccTransportLabel: newOccTransportConfig ? newOccTransportConfig.label : dataToMerge.transportLabel,
                                    newOccFrequenceLabel: planTrip.newOccFrequence != null ? (planTrip.newOccFrequence != null ? planTrip.newOccFrequence : 0) + ` fois par ${planTrip.tripTypeId == 'holiday' ? 'an' : 'mois'}` : dataToMerge.frequenceLabel,
                                }
                            }
                        }
                        planTripsFormatted.push({ ...planTrip, ...dataToMerge })
                    }
                })
                return planTripsFormatted
            },
            preFormatData(){
                return {
                    checkInformationNextEvent   : this.moreInformations,
                    newWayTested                : this.newWay,
                    choice1                     : this.whyNewWay.includes(1),
                    choice2                     : this.whyNewWay.includes(2),
                    choice3                     : this.whyNewWay.includes(3),
                    choice4                     : this.whyNewWay.includes(4),
                    choice5                     : this.whyNewWay.includes(5),
                    choice6                     : this.whyNewWay.includes(6),
                    choice7                     : this.whyNewWay.includes(7),
                    choice8                     : this.whyNewWay.includes(8),
                    commentSurvey               : this.commentSurvey,
                }
            },
            isRessourcesDisplay(){
                return this.newWay !== false
            },
            isDisabled(){
                return this.moreInformations === null
            }
        },
        components: {
            'choices': ChoicesTemplate,
            TripChanges,
            CtaFooter
        }
    }
</script>

<style scoped>
.survey-container{
    margin: auto;
    max-width: 800px;
    padding: 50px 0;
}
</style>