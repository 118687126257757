export default [
    {
        label: "Voiture thermique (diesel ou essence)",
        value: 0,
        examples: []
    },
    {
        label: "Moto ou scooter thermique (diesel ou essence)",
        value: 1,
        examples: []
    },
    {
        label: "Voiture électrique / hybride",
        value: 2,
        examples: [
            {
                value: 0,
                label: "Connaître les aides pour l'achat d'un véhicule propre"
            },
            {
                value: 1,
                label: "Etudier les solutions de reprises sur mon véhicule"
            },
            {
                value: 2,
                label: "S'informer pour savoir de quel type de véhicule j'ai besoin"
            },
            {
                value: 3,
                label: "Acheter / louer un nouveau véhicule"
            },
        ]
    },
    {
        label: "Moto ou scooter électrique",
        value: 3,
        examples: [
            {
                value: 0,
                label: "Connaître les aides pour l'achat d'un véhicule propre"
            },
            {
                value: 1,
                label: "Etudier les solutions de reprises sur mon véhicule"
            },
            {
                value: 2,
                label: "S'informer pour savoir de quel type de véhicule j'ai besoin"
            },
            {
                value: 3,
                label: "Acheter / louer un nouveau véhicule"
            },
        ]
    },
    {
        label: "Covoiturage",
        value: 4,
        examples: [
            {
                value: 0,
                label: "Choisir une application de mise en relation / Un moyen de mise en relation avec les covoitureurs"
            },
            {
                value: 1,
                label: "Déterminer un lieu de prise en charge"
            },
            {
                value: 2,
                label: "Connaître des personnes de mon quartier/village qui font du covoiturage"
            },
            {
                value: 3,
                label: "Connaître des personnes de mon lieu de travail qui font du covoiturage"
            },
            {
                value: 4,
                label: "Connaître les modalités de retour si je dois rentrer plus tôt ou si mon covoiturage ne peut avoir lieu"
            },
        ]
    },
    {
        label: "Autopartage",
        value: 5,
        examples: [
            {
                value: 0,
                label: "Connaître le fonctionnement du système d'autopartage / l'emplacement des bornes"
            },
            {
                value: 1,
                label: "Prendre un abonnement au service d'autopartage"
            }
        ]
    },
    {
        label: "Vélo électrique",
        value: 6,
        examples: [
            {
                value: 0,
                label: "Achat d'un vélo neuf / d'occasion"
            },
            {
                value: 1,
                label: "Louer un vélo pendant une courte durée pour tester"
            },
            {
                value: 2,
                label: "Réparer ou réviser mon vélo / Faire réparer ou réviser mon vélo / Me rendre à une permanence d'autoréparation"
            },
            {
                value: 3,
                label: "S'équiper pour rouler de nuit : feux avant/arrière, gilet réfléchissant"
            },
            {
                value: 4,
                label: "S'équiper pour tous les temps : cape imperméable, garde-boue"
            },
            {
                value: 5,
                label: "S'équiper pour transporter mes bagages : sac à dos, remorque, porte bagage, saccoches"
            },
            {
                value: 6,
                label: "S'équiper pour être en sécurité : casque, antivol, feux avant/ arrière"
            },
            {
                value: 7,
                label: "Mieux connaître mon trajet : repérer les pistes cyclables, les bornes de vélos en libre service, les emplacement pour garer son vélo"
            },
            {
                value: 8,
                label: "Bénéficier de l'aide financière à la réparation d'un vélo"
            },
            {
                value: 9,
                label: "Obtenir une remise à niveau en vélo"
            },
            {
                value: 10,
                label: "Faire le trajet une première fois avec quelqu’un qui le pratique"
            },
            {
                value: 11,
                label: "Obtenir l'aide de l’état « Bonus VAE »"
            },
            {
                value: 12,
                label: "Bénéficier de la location de vélo VAE longue durée"
            },
            {
                value: 13,
                label: "Bénéficier de la prime à l'achat VAE CDA"
            },
        ]
    },
    {
        label: "Trottinette électrique",
        value: 7,
        examples: [
            {
                value: 0,
                label: "Achat d'une trottinette neuve / d'occasion"
            },
            {
                value: 1,
                label: "Louer une trottinette pendant une courte durée pour tester"
            },
            {
                value: 2,
                label: "Réparer ou réviser ma trottinette / Faire réparer ou réviser ma trottinette"
            },
            {
                value: 3,
                label: "S'équiper pour rouler de nuit : feux avant/arrière, gilet réfléchissant"
            },
            {
                value: 4,
                label: "S'équiper pour tous les temps : cape imperméable, garde-boue"
            },
            {
                value: 5,
                label: "S'équiper pour transporter mes bagages : sac à dos, saccoches"
            },
            {
                value: 6,
                label: "S'équiper pour être en sécurité : casque, antivol, feux avant/ arrière"
            },
            {
                value: 7,
                label: "Mieux connaître son trajet : repérer les pistes pour rouler en sécurité"
            },
        ]
    },
    {
        label: "Marche",
        value: 8,
        examples: [
            {
                value: 0,
                label: "Connaître mon trajet"
            },
            {
                value: 1,
                label: "S'équiper pour tous les temps : vêtement de pluie, ..."
            },
            {
                value: 2,
                label: "Avoir de quoi transporter mon matériel / mes courses : sac cabas, sac à dos, ..."
            },
            {
                value: 3,
                label: "Des écouteurs pour continuer à écouter mon émission de radio préférée pendant mon trajet"
            },
        ]
    },
    {
        label: "Vélo",
        value: 9,
        examples: [
            {
                value: 0,
                label: "Achat d'un vélo neuf / d'occasion"
            },
            {
                value: 1,
                label: "Louer un vélo pendant une courte durée pour tester / prendre un abonnement libre service vélo"
            },
            {
                value: 2,
                label: "Réparer ou réviser mon vélo / Faire réparer ou réviser mon vélo / Me rendre à une permanence d'autoréparation"
            },
            {
                value: 3,
                label: "S'équiper pour rouler de nuit : feux avant/arrière, gilet réfléchissant"
            },
            {
                value: 4,
                label: "S'équiper pour tous les temps : cape imperméable, garde-boue"
            },
            {
                value: 5,
                label: "S'équiper pour transporter mes bagages : sac à dos, remorque, porte bagage, saccoches"
            },
            {
                value: 6,
                label: "S'équiper pour être en sécurité : casque, antivol, feux avant / arrière"
            },
            {
                value: 7,
                label: "Mieux connaître mon trajet : repérer les pistes cyclables , les bornes de vélos en libre service, les emplacement pour garer son vélo"
            },
            {
                value: 8,
                label: "Bénéficier de l'aide financière à la réparation d'un vélo"
            },
            {
                value: 9,
                label: "Obtenir une remise à niveau en vélo"
            },
            {
                value: 10,
                label: "Faire le trajet une première fois avec quelqu’un qui le pratique"
            },
        ]
    },
    {
        label: "Trottinette",
        value: 10,
        examples: [
            {
                value: 0,
                label: "Achat d'une trottinette neuve / d'occasion"
            },
            {
                value: 1,
                label: "Louer une trottinette pendant une courte durée pour tester"
            },
            {
                value: 2,
                label: "Réparer ou réviser ma trottinette / Faire réparer ou réviser ma trottinette"
            },
            {
                value: 3,
                label: "S'équiper pour rouler de nuit : feux avant/arrière, gilet réfléchissant"
            },
            {
                value: 4,
                label: "S'équiper pour tous les temps : cape imperméable, garde-boue"
            },
            {
                value: 5,
                label: "S'équiper pour transporter mes bagages : sac à dos, saccoches"
            },
            {
                value: 6,
                label: "S'équiper pour être en sécurité : casque, antivol, feux avant / arrière"
            },
            {
                value: 7,
                label: "Mieux connaître son trajet : repérer les pistes pour rouler en sécurité"
            },
        ]
    },
    {
        label: "Transport collectif (bus, tram, train, bateau)",
        value: 11,
        examples: [
            {
                value: 0,
                label: "Obtenir un abonnement transport en commun / connaître les tarifs des tickets en fonction de ma situation"
            },
            {
                value: 1,
                label: "Obtenir le financement employeur trajet domicile-travail"
            },
            {
                value: 2,
                label: "Connaître les emplacements des arrêts / les lignes de bus / les horaires qui me concernent et mon temps de trajet"
            },
            {
                value: 3,
                label: "S'équiper pour transporter mon matériel : sac à dos/sacoche facile à transporter"
            },
        ]
    },
    {
        label: "Avion",
        value: 12,
        examples: []
    },
]