<template>
    <div class="bg-blue-100 rounded-lg m-auto" style="max-width: 280px;min-width: 200px;">
        <icon-toggle
            :id="trip.tripTypeId"
            :icon="trip.tripIcon"
            :label="trip.tripLabel"
            :sublabel="trip.otherTxt"
            :active="true"
            class="m-auto"
        />
    
        <div class="m-auto mt-4" style="max-width: 250px;">
            <h2 class="font-bold text-blue">Mes habitudes :</h2>
            <div class="text-blue font-bold rounded-xl p-1 text-sm">
                <p>{{ trip.transportLabel }}</p>
                <p>{{ trip.frequenceLabel }}</p>
            </div>
        </div>
        <div><i class="el-icon-bottom font-bold"></i></div>
        <div class="px-4 m-auto pb-4">
            <div class="text-blue font-bold rounded-lg p-1 text-sm m-auto" style="max-width: 280px; min-width: 200px;">
                <h2 class="text-base">Je change pour :</h2>
                <p>{{ trip.newTransportLabel }}</p>
                <p>{{ trip.newFrequenceLabel }}</p>
                <h2 class="text-base" v-if="trip.newOccTransportLabel && trip.newOccFrequenceLabel">Et en complément :</h2>
                <p>{{ trip.newOccTransportLabel }}</p>
                <p>{{ trip.newOccFrequenceLabel }}</p>
            </div>
        </div>
    </div>
</template>

<script>
    import IconToggle from './IconToggle.vue'
    export default {
        components: { IconToggle },
        props: {
            trip: {
                type: Object,
                required: true
            }
        },
    }
</script>

<style scoped>

</style>