export default {
    no : {
        key: 'no',
        label : "Quelles sont les raisons qui font que tu n'as pas testé ?",
        ressourcesLabel: "Pas de souci, ce n’est jamais simple de changer d’habitude. <br /><br />&#128077; <span style='font-weight: bold;'>Envie d’un coup de pouce ?</span> &#128077; <br />Viens échanger lors d’une rencontre conviviale pour partager motivations, difficultés, astuces et témoignages avec les autres participants à Mon Action Mobilité Durable.<br /><br />Pour nous rencontrer : <br /><br /><a href='https://my.weezevent.com/mon-action-mobilite-durable-echanges-et-partages-dexperience' class='bg-blue text-white font-bold p-2 rounded-md hover:bg-blue-dark'>C'est par ici !</a>",
        options : [
            {
                value: 1,
                label: "Je n’ai pas pris le temps ou bien j’ai oublié mais je vais essayer"
            },
            {
                value: 2,
                label: "Je n’ai pas réussi à me motiver"
            },
            {
                value: 3,
                label: "Je manque d'information"
            },
            {
                value: 4,
                label: "Je dois m'équiper"
            },
            {
                value: 5,
                label: "L’offre et/ou les aménagements ne sont pas adaptés à ma situation."
            },
            {
                value: 6,
                label: "Je n’ai pas le budget"
            },
            {
                value: 7,
                label: "Ma situation a changé"
            },
            {
                value: 8,
                label: "Autre raison"
            }
        ]
    },
    yesNeverTryAgain : {
        key: 'yesNeverTryAgain',
        label : "Pourquoi as-tu abandonné cette solution de déplacement ?",
        ressourcesLabel: "Pas de souci, ce n’est jamais simple de changer d’habitude. <br /><br />&#128077; <span style='font-weight: bold;'>Envie d’un coup de pouce ?</span> &#128077; <br />Viens échanger lors d’une rencontre conviviale pour partager motivations, difficultés, astuces et témoignages avec les autres participants à Mon Action Mobilité Durable.<br /><br />Pour nous rencontrer : <br /><br /><a href='https://my.weezevent.com/mon-action-mobilite-durable-echanges-et-partages-dexperience' class='bg-blue text-white font-bold p-2 rounded-md hover:bg-blue-dark'>C'est par ici !</a>",
        options : [
            {
                value: 1,
                label: "Je suis vite revenu.e à mes anciennes habitudes, mais je vais retenter"
            },
            {
                value: 2,
                label: "Je n’ai pas assez de motivation pour me sortir de mon habitude"
            },
            {
                value: 3,
                label: "Je n'ai pas trouvé toutes les informations souhaitées pour poursuivre ma démarche"
            },
            {
                value: 4,
                label: "Je dois mieux m'équiper"
            },
            {
                value: 5,
                label: "L’offre et/ou les aménagements ne sont pas adaptés à ma situation"
            },
            {
                value: 6,
                label: "Je n’ai pas le budget"
            },
            {
                value: 7,
                label: "Ma situation a changé"
            },
            {
                value: 8,
                label: "Autre raison"
            }
        ]
    },
    yesTrySometimes : {
        key: 'yesTrySometimes',
        label : "D'après toi qu'est-ce qui te manque pour que cette solution de déplacement devienne une habitude ?",
        ressourcesLabel: "Bravo pour tes efforts ! Pour t’aider à te motiver encore plus le collectif Agremob peut t’aider à en faire une vraie nouvelle habitude si tu le souhaites.<br /><br />&#128077; <span style='font-weight: bold;'>Envie d’un coup de pouce ?</span> &#128077; <br />Viens échanger lors d’une rencontre conviviale pour partager motivations, difficultés, astuces et témoignages avec les autres participants à Mon Action Mobilité Durable.<br /><br />Pour nous rencontrer : <br /><br /><a href='https://my.weezevent.com/mon-action-mobilite-durable-echanges-et-partages-dexperience' class='bg-blue text-white font-bold p-2 rounded-md hover:bg-blue-dark'>C'est par ici !</a>",
        options : [
            {
                value: 1,
                label: "Pas grand-chose, c'est sur le point de devenir une habitude"
            },
            {
                value: 2,
                label: "Une plus grande motivation personnelle ou émulation de groupe (challenge entre collègues ou amis, une mode qui se développe, le soutien des proches)"
            },
            {
                value: 3,
                label: "Plus d'informations"
            },
            {
                value: 4,
                label: "Je dois mieux m'équiper"
            },
            {
                value: 5,
                label: "Une meilleure offre de services"
            },
            {
                value: 6,
                label: "Des incitations financières (forfait mobilité via mon employeur, prime à l’achat, …)"
            },
            {
                value: 7,
                label: "Un emploi du temps plus souple"
            },
            {
                value: 8,
                label: "Autre raison"
            }
        ]
    },
    yes : {
        key: 'yes',
        label : "Bravo tu peux être fier.e de toi",
        ressourcesLabel: "BRAVO, tu peux être fier.e de toi !<br /><br />Envie de témoigner de ton expérience pour inciter d’autres personnes à passer à l’action ? <br />Viens échanger lors d’une rencontre conviviale pour partager motivations, difficultés, astuces et témoignages avec les autres participants à Mon Action Mobilité Durable.<br /><br />Pour nous rencontrer : <br /><br /><a href='https://my.weezevent.com/mon-action-mobilite-durable-echanges-et-partages-dexperience' class='bg-blue text-white font-bold p-2 rounded-md hover:bg-blue-dark'>C'est par ici !</a>",
        options : []
    }
}