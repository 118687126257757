<template>
    <!-- <i class="icon-iconmobilite_weekend"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span><span class="path13"></span><span class="path14"></span></i> -->
    <img src="../../assets/icons/weekend.png" alt="icon-weekend" />
</template>

<script>
    export default {
        name: 'IconWeekend',
        props: [],
        data(){
            return {
            }
        },
    }
</script>