<template>
    <!-- <i class="icon-iconmobilite_travail"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></i> -->
    <img src="../../assets/icons/work.png" alt="icon-work" />
</template>

<script>
    export default {
        name: 'IconWork',
        props: [],
        data(){
            return {
            }
        },
    }
</script>