<template>
    <div class="icon-toggle cursor-pointer text-green" :class="{'active' : active}" @click="$emit('toggle', id)">
        <component :is="icon" class="m-auto" :class="classWidth"/>
        <div class="pt-1 md:text-sm text-xs">{{ label }}</div>
        <div class="pt-1 text-xs" v-if="sublabel != ''">{{ sublabel }}</div>
    </div>
</template>

<script>
    import IconActivity from '@/components/icons/IconActivity.vue'
    import IconBigerrand from '@/components/icons/IconBigerrand.vue'
    import IconDoctor from '@/components/icons/IconDoctor.vue'
    import IconFriends from '@/components/icons/IconFriends.vue'
    import IconOther from '@/components/icons/IconOther.vue'
    import IconSchool from '@/components/icons/IconSchool.vue'
    import IconSmallerrand from '@/components/icons/IconSmallerrand.vue'
    import IconWeekend from '@/components/icons/IconWeekend.vue'
    import IconWork from '@/components/icons/IconWork.vue'
    import IconHoliday from '@/components/icons/IconHoliday.vue'
    export default {
        props: {
            id: {
                type: String,
                required: false,
                default: ''
            },
            icon: {
                type: String,
                required: false,
                default: ''
            },
            label: {
                type: String,
                required: false,
                default: ''
            },
            sublabel: {
                type: String,
                required: false,
                default: ''
            },
            active: {
                type: Boolean,
                required: false,
                default: false
            },
            classWidth: {
                type: String,
                required: false,
                default: 'w-1/5'
            }
        },
        components: {
            IconActivity,
            IconBigerrand,
            IconDoctor,
            IconFriends,
            IconOther,
            IconSchool,
            IconSmallerrand,
            IconWeekend,
            IconWork,
            IconHoliday
        }
    }
</script>

<style scoped lang="scss">
.icon-toggle{
    min-height: 100px;
    padding: 8px;
    border-radius: 10px;
    height: 120px;
    overflow: hidden;
    &.active{
        background-color: #0ea993;
        color: white;
    }
}
</style>