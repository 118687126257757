<template>
    <div>
        <div class="sm:hidden step-validate-container">
            <div class="step-validate-right" @click="$emit('send')">
                {{text}}
            </div>
        </div>
        <!-- <div class="step-validate-container--button hidden sm:block mt-4 dark:bg-gray-800">
            <el-button type="primary" @click="previousStep" class="uppercase">Précedent</el-button>
            <el-button type="default" @click="$emit('next')" class="uppercase">{{text}}</el-button>
        </div> -->
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    export default {
        props: {
            text: {
                type: String,
                required: false,
                default: 'SUIVANT'
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        computed: {
            ...mapGetters([
                'isHoliday'
            ]),
        },
        methods: {
            previousStep(){
                this.$emit('previous')
                if(this.isHoliday && this.$route.meta.previousHoliday){
                    this.$router.push({name: this.$route.meta.previousHoliday })
                } else{
                    this.$router.push({name: this.$route.meta.previous })
                }
            },
        }
    }
</script>

<style scoped>
.step-validate-left{
    padding: 8px;
    flex: 0 1 50%;
    background-color: #0ea993;
}
.step-validate-right{
    padding: 8px;
    width: 100%;
    background-color: #0a3ba2;
}

.step-validate-container{
    position: fixed;
    display: flex;
    bottom: 0;
    left: 0;
    box-shadow: 0px 0px 10px #c7c7c7;
    z-index: 1;
    color: white;
    font-weight: bold;
    cursor: pointer;
    width:100%;
}
.step-validate-container--button{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: white;
    padding: 8px;
    z-index: 1;
    box-shadow: 0px 0px 10px #c7c7c7;
}
</style>
