<template>
    <div class="text-left">
        <el-checkbox-group v-model="listLocal">
            <p v-for="(choice, index) in choices" 
                :key="index">
                <el-checkbox :label="choice.value">{{ choice.label }}</el-checkbox>
            </p>
        </el-checkbox-group>
    </div>
</template>

<script>
    export default {
        props: {
            choices: {
                type: Array,
                required: true,
                default : () => []
            },
            value: {
                type: Array,
                required: true
            }
        },
        computed: {
            listLocal: {
                get: function() {
                    return this.value
                },
                set: function(value) {
                    this.$emit('update', value)
                }
            }
        }
    }
</script>

<style scoped>

</style>