export default [
    {
        id: "work",
        icon: "icon-work",
        label: "Me rendre au travail"
    },
    {
        id: "smallerrand",
        icon: "icon-smallerrand",
        label: "Aller faire des petites courses",
    },
    {
        id: "bigerrand",
        icon: "icon-bigerrand",
        label: "Aller faire des grosses courses"
    },
    {
        id: "activity",
        icon: "icon-activity",
        label: "Aller à une activité (loisirs, association)"
    },
    {
        id: "weekend",
        icon: "icon-weekend",
        label: "Partir en week-end"
    },
    {
        id: "school",
        icon: "icon-school",
        label: "Amener mes enfants à l'école"
    },
    {
        id: "friends",
        icon: "icon-friends",
        label: "Rendre visite à ma famille / amis"
    },
    {
        id: "doctor",
        icon: "icon-doctor",
        label: "Aller à un RDV médical"
    },
    {
        id: "holiday",
        icon: "icon-holiday",
        label: "Vacances"
    },
    {
        id: "other",
        icon: "icon-other",
        label: "Autre"
    },
]